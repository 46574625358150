import CouponIcon from "@rsuite/icons/Coupon";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import ImageIcon from "@rsuite/icons/Image";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  Checkbox,
  IconButton,
  Input,
  Loader,
  SelectPicker,
  Tag,
} from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";
import { ImCheckboxChecked } from "react-icons/im";
import { ENDPOINTS } from "../../Api/enpoints";
import { clientIdAtom } from "../../Atoms/clientId";
import { SaleUmrahAtom } from "../../Atoms/saleOmrah.atom";
import { ConfirmationStatus } from "../../Constants/types";
import { OrderUmrahModel } from "../../Models/orderUmrahModel";
import { multiDataSet } from "./excel_data";
import { BASE_URL } from "../../Config/api.config";
import validate from "../../Helpers/validate";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { PacksUmrahAtom } from "../../Atoms/packs.atom";
import { clientAtom } from "../../Atoms/client.atom";
import { Modal } from "rsuite";
import { MdQrCode } from "react-icons/md";
export default function SaleUmrah(props) {
  // STATE
  const [data, setdata] = useState([]);
  const packages = useRecoilValue(PacksUmrahAtom);
  const [client, setclient] = useRecoilState(clientAtom);

  const [forcasts, setforcasts] = useState([]);
  const [vols, setvols] = useState([]);
  const [checkeds, setcheckeds] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  // Set the total number of images
  const [loadPrint, setloadPrint] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [id, setid] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
    forCentral: false,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [clientId, setclientId] = useRecoilState(clientIdAtom);
  const [model, setmodel] = useRecoilState(SaleUmrahAtom);
  const handleImageLoad = () => {
    setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
  };
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new OrderUmrahModel());
    setError("");
  };
  // API CALLS

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };
  useEffect(() => {
    let c = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).clientId
      : 0;
    setclientId(c);
    APi.createAPIEndpoint(ENDPOINTS.Forcast, {
      clientId: c,
      page: 1,
      take: 100,
    })
      .customGet()
      .then((res) => {
        let _vols = res.data.data.map((el) => ({
          packageUmrahId: el.packageUmrahId,
          ...el.disponibility,
        }));
        console.log(_vols);
        setvols(_vols.filter((d) => d.onStock));
        setforcasts(res.data.data);
      });
  }, []);
  const save = (fromVerify = false) => {
    let msg = validate(model.customer, [
      { firstName: "Prénom" },
      { firstNameAr: "Prénom Arabe" },
      { lastName: "Nom" },
      { lastNameAr: "Nom Arabe" },
      { phoneNumber: "Numero de tél" },

      // { clientId: "Client" },
    ]);
    let item = forcasts.find(
      (el) =>
        el.disponibilityId == model.disponibilityId &&
        el.clients.find(
          (el1) =>
            el1.clientId === clientId ||
            (client && el1.clientId == client.belongsToClientId)
        )
    );
    let _c = null;
    if (item) {
      _c = item.clients.find(
        (el) =>
          el.clientId === clientId ||
          (client && el.clientId == client.belongsToClientId)
      );
    }
    console.log(item);
    console.log(_c);
    if (
      _c &&
      _c.nbr <= _c.nbrConfirmed + _c.nbrNonConfirmedByClient + _c.nbrPending &&
      !model.id
      //  &&
      // (!item.clients.find(
      //   (el) => el.client && el.client.name === "UMRAH PUBLIC"
      // ) ||
      //   !item.clients.find(
      //     (el) => el.client && el.client.name === "UMRAH PUBLIC"
      //   ).nbr)
    ) {
      setError("Non Disponible! Nombre atteint!");
    } else if (msg) setError(msg);
    else {
      console.log(model);

      setstate((prev) => {
        return { ...prev, loading: true };
      });
      let m = {
        ...model,
      };

      delete m.client;
      delete m.disponibility;
      delete m.packageUmrah;
      delete m.price;
      m.clientId = clientId;
      if (m.id) {
        setError("");
        if (m.confirmationStatus == 3 && !fromVerify) {
          Swal.fire({
            position: "top-center",
            icon: "warning",
            title:
              "Cet achat a été confirmé , veuillez contacter la direction !",
            showConfirmButton: true,
            timer: 3500,
          });
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        } else {
          APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
            .update2(m)
            .then((res) => {
              fetch();
              reset();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Element a été bien ajouté !",
                showConfirmButton: false,
                timer: 1500,
              });
              setstate((prev) => {
                return { ...prev, open: false, loading: false };
              });
            })
            .catch((e) => {
              if (e.response) {
                setError(e.response.data);
                Swal.fire({
                  position: "top-center",
                  icon: "warning",
                  title: e.response.data,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              setstate((prev) => {
                return { ...prev, loading: false };
              });
            });
        }
      } else {
        setError("");

        APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
          .create(m)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            if (e.response) {
              setError(e.response.data);
              Swal.fire({
                position: "top-center",
                icon: "warning",
                title: e.response.data,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
    // }
  };
  const deleteAction = (id) => {
    let m = data.find((el) => el.id == id);
    if (m.confirmationStatus == 3) {
      Swal.fire({
        position: "top-center",
        icon: "warning",
        title: "Cet achat a été confirmé , veuillez contacter la direction !",
        showConfirmButton: true,
        timer: 3500,
      });
      setstate((prev) => {
        return { ...prev, loading: false };
      });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
        .delete(id)

        .then((res) => {
          fetch();
          Swal.fire("Supprimé !", "", "success");
        })
        .catch((e) => setError(e.Message));
    }
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
      .fetchById(id)
      .then((res) => {
        setmodel((prev) => {
          let m = { ...res.data };
          m.date = m.date.substring(0, 10);
          let customer = { ...m.customer };

          return {
            ...m,

            customer,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handlePrint = () => {
    setloadPrint(true);
    const iframe = document.getElementById("iframe");
    if (checkeds.length && imagesLoaded === checkeds.length) {
      iframe.contentDocument.body.innerHTML = "";

      const content = generateHTMLContent();
      iframe.contentDocument.write(content);
      iframe.contentWindow.print();
      setloadPrint(false);
      setImagesLoaded(0);
    } else
      setTimeout(() => {
        if (checkeds.length && imagesLoaded === checkeds.length) {
          iframe.contentDocument.body.innerHTML = "";

          const content = generateHTMLContent();
          iframe.contentDocument.write(content);
          iframe.contentWindow.print();
          setloadPrint(false);
          setImagesLoaded(0);
        } else
          setTimeout(() => {
            iframe.contentDocument.body.innerHTML = "";

            const content = generateHTMLContent();
            iframe.contentDocument.write(content);
            iframe.contentWindow.print();
            setloadPrint(false);
            setImagesLoaded(0);
          }, 5000);
      }, 10000);
  };

  function generateHTMLContent() {
    // Replace this with your dynamic content generation logic

    let els = data.filter((p1) => checkeds.find((_p) => _p == p1.id));

    // Generate a list of image tags dynamically
    const imageTags = els
      .map((el) => el.customer)
      .map(
        (v, index) =>
          `  <img style="display:inline-block; width:7.9in;
          height:11.5in; 
border-radius:10px; vertical-align:top ; margin:auto
          ;" src=${BASE_URL + "Uploads/Visas/" + v.passportNumber + ".png"} />
          
       `
      )
      .join("");
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    // The complete HTML document structure
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${Date.now()}</title>
      </head>
      <body>
 ${imageTags}
      </body>
      </html>
    `;
  }
  // LIFE CYCLES

  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "id",
      value2: "hasVisa",
      name: " ",
      render: (id, hasVisa) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            disabled={!hasVisa}
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => (hasVisa ? [...prev, id] : prev));
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
        </b>
      ),
    },
    {
      value: "packageUmrah",
      value2: "onlyTicket",
      value3: "withoutVisa",
      value4: "withoutTicket",
      name: "Voyage",
      render: (v, onlyTicket, withoutVisa, withoutTicket) => (
        <b style={{ color: "#549f0a" }}>
          {v ? v.name.toUpperCase() : ""}{" "}
          {onlyTicket ? <Tag color="blue">Billet</Tag> : ""}{" "}
          {withoutVisa ? <Tag color="orange">sans visa</Tag> : ""}
          {withoutTicket ? <Tag color="violet">sans billet</Tag> : ""}
        </b>
      ),
    },
    {
      value: "disponibility",
      name: "Vol",
      render: (c) =>
        c ? (
          <b
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px",

              borderRadius: "5px",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px", margin: "0 6px" }}
              src={c.airline ? c.airline.logo : ""}
            ></img>
            <b>
              {" "}
              <div style={{ color: "#232323" }}>
                {moment(c.date).format("DD MMM YYYY")} ({" "}
                <i style={{ color: "#999" }}>
                  {packages &&
                    packages.find((l) => l.id == c.packageUmrahId) &&
                    packages.find((l) => l.id == c.packageUmrahId).name}
                </i>{" "}
                )
              </div>
              <b style={{ color: "#888", fontWeight: "lighter" }}>
                {" "}
                {c.airline ? c.airline.name : ""}
              </b>
            </b>
          </b>
        ) : (
          <b></b>
        ),
    },
    {
      value: "confirmationStatus",
      name: "Etat",
      render: (v) => (
        <Tag
          color={
            v === 2 ? "violet" : v === 3 ? "green" : v === 4 ? "red" : "yellow"
          }
        >
          {ConfirmationStatus.find((el) => v === null || el.value === v)
            ? ConfirmationStatus.find((el) => v === null || el.value === v)
                .label
            : ConfirmationStatus.find((el) => el.value === 1).label}
        </Tag>
      ),
    },
    {
      value: "fingerprint",
      value2: "id",
      name: "Empreinte",
      render: (v, id) => (
        <Checkbox
          checked={v}
          onClick={(e) => {
            createAPIEndpoint(ENDPOINTS.Order + "/Umrah/fingerprint/" + id)
              .customGet()
              .then((res) => {
                let i = data.findIndex((item) => item.id == id);
                let el = { ...data[i] };
                el.fingerprint = !v;
                let _data = [...data];
                _data[i] = el;
                setdata(_data);
                alert("Element a été modifié !");
              });
          }}
        />
      ),
    },
    {
      value: "customer",
      name: "Client B2C",
      render: (v) => (
        <a style={{ display: "flex", alignItems: "center" }}>
          {v.picture ? (
            <img
              alt=""
              src={
                BASE_URL +
                "Uploads/CustomerPictures/" +
                v.picture +
                "?" +
                Date.now()
              }
              width="43"
              height="43"
              style={{
                border: "1px solid #eee",
                borderRadius: "50%",
                boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "15px",
                border: "1px dashed #888",
                borderRadius: "50%",
                width: "43px",
                height: "43px",
                textAlign: "center",
                lineHeight: "42px",
              }}
            >
              <ImageIcon color="#3598ff"></ImageIcon>
            </div>
          )}
          <div style={{ padding: "0 5px" }}>
            {v ? v.name.toUpperCase() : ""}
            <br></br>{" "}
            <i style={{ color: "#aaa" }}>{v ? v.passportNumber : ""}</i>
          </div>
        </a>
      ),
    },
    {
      value: "id",
      value2: "coupon",
      name: "+ Coupon",
      render: (v, v2) => (
        <Button
          onClick={() => {
            setid(v);
            getBYId(v);
          }}
          size="s"
          color="yellow"
          appearance="ghost"
          disabled={v2}
        >
          <MdQrCode /> Coupon
        </Button>
      ),
    },
    {
      value: "totalPrice",
      value2: "couponReduction",
      name: "Prix Total",
      render: (v, v2) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {(v - v2).toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            TND
          </span>
        </b>
      ),
    },

    {
      value: "date",
      name: "Date De Vente",
      render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
    },
    {
      value: "hasVisa",
      value2: "customer",
      name: "Visa",
      render: (v, v2) => (
        <IconButton
          onClick={() => {
            let a = document.createElement("a");
            a.href = BASE_URL + "Uploads/Visas/" + v2.passportNumber + ".png";
            a.setAttribute("target", "_blank");
            a.click();
            console.log(a);
            document.removeChild(a);
          }}
          size="xs"
          icon={<FileDownloadIcon />}
          color="green"
          appearance="primary"
          disabled={!v}
        >
          Visa
        </IconButton>
      ),
    },
    {
      value: "ticket_url",
      name: "Billet",
      render: (v) => (
        <IconButton
          disabled
          size="xs"
          icon={<CouponIcon />}
          onClick={() => {}}
          color="blue"
          appearance="primary"
        >
          Billet
        </IconButton>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <div style={{ overflow: "hidden", height: 0 }}>
        <iframe id="iframe" title="printFrame" width="100%"></iframe>

        {data
          .filter((p1) => checkeds.find((_p) => _p == p1.id))
          .map((el) => el.customer)
          .map((c) => (
            <img
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              src={BASE_URL + "Uploads/Visas/" + c.passportNumber + ".png"}
              key={c.id}
              alt=""
            ></img>
          ))}
      </div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Status : </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(ConfirmationStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Nom Omrah: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              packages.map((el) => {
                return { label: el.name, value: el.id };
              })
            )}
            block
            noSearch
            value={filterModel.packageUmrahId}
            onSelect={(packageUmrahId) => {
              setfilterModel((prev) => {
                return { ...prev, packageUmrahId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Vols: </label>

          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              vols
                ? vols.map((el, i) => {
                    return {
                      label: (
                        <div
                          style={{
                            color: "#232323",
                            background: i % 2 ? "rgba(11,150,102,0.2)" : "",
                          }}
                        >
                          {moment(el.date).format("DD MMM YYYY")}({" "}
                          <i style={{ color: "#999" }}>
                            {packages &&
                              packages.find((l) => l.id == el.packageUmrahId) &&
                              packages.find((l) => l.id == el.packageUmrahId)
                                .name}
                          </i>
                          )
                        </div>
                      ),
                      value: el.id,
                    };
                  })
                : []
            )}
            block
            noSearch
            value={filterModel.disponibilityId}
            onSelect={(disponibilityId) => {
              setfilterModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        excelData={multiDataSet(data)}
        ActionOnClose={reset}
        nameExcel="saleUmrah"
        size="lg"
        save={save}
        noModify={model.confirmationStatus == 3}
        AddComponent={
          <AddEdit
            error={error}
            forcasts={forcasts}
            vols={vols}
            seterror={setError}
            packs={packages}
          />
        }
      />{" "}
      <div
        onClick={(e) =>
          setcheckeds((prev) =>
            prev.length
              ? []
              : data.filter((el) => el.hasVisa).map((el) => el.id)
          )
        }
        style={{
          display: "inline-block",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout{" "}
        <Button
          loading={loadPrint}
          appearance="primary"
          color="blue"
          onClick={handlePrint}
        >
          imprimer visas
        </Button>
      </div>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        noAdvancedActions
        deleteAction={deleteAction}
        actionKey="id"
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          // first
          // last
          ellipsis
          // boundaryLinks
          maxButtons={5}
          size="sm"
          layout={["total", "-", "limit", "pager"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
      <AddCoupon id={id} setid={setid} save={save} _setmodel={setmodel} />
    </div>
  );
}

const AddCoupon = ({ id, setid, save, _setmodel, loading }) => {
  const [verify, setverify] = useState("");
  const [verifyRs, setverifyRs] = useState("");
  const check = () => {
    if (verify.length == 8) {
      createAPIEndpoint(ENDPOINTS.Survey + "/verifyCoupon")
        .create({ coupon: verify })
        .then((res) => {
          _setmodel((prev) => ({
            ...prev,
            couponReduction: 200,
            coupon: verify,
          }));
          setverifyRs("Valide");
        })
        .catch((e) => {
          setverifyRs(e.response.data);
        });
    } else setverifyRs("Coupon doit contenir 8 caractère");
  };
  // const save = ()=>{
  //   createAPIEndpoint(ENDPOINTS.Order+"/saveCoupon").create({id, coupon:verify}).then(res=>{
  //     setid(0)
  //     alert("Enregistré")
  //   })
  // }
  return (
    <Modal
      style={{ maxHeight: "95vh", overflow: "auto" }}
      size="lg"
      overflow={false}
      open={id > 0}
      onClose={() => {
        setid(0);
        setverify("");
      }}
    >
      <Modal.Header>
        <Modal.Title>Ajouter Vente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Le pelerin a un code coupon?
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            style={{ flex: 1, marginRight: "5px" }}
            value={verify}
            onChange={(v) => (v.length <= 8 ? setverify(v.toUpperCase()) : {})}
          />
          <Button onClick={check} appearance="primary" color="green">
            Vérifier
          </Button>
        </div>
        {verifyRs && (
          <div style={{ color: verifyRs == "Valide" ? "green" : "red" }}>
            {verifyRs}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={verifyRs != "Valide"}
          onClick={() => save(true)}
          appearance="primary"
        >
          {loading ? <Loader size="sm" /> : "Enregistrer"}
        </Button>
        <Button
          onClick={() => {
            setid(0);
          }}
          appearance="subtle"
        >
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
